<template>
  <div class="">
    <span @click="seeQuote" class=" hand openQuote flc">
      <i class="iconfont icon-yinyong1"></i>
      引用
    </span>
    <myDialog title="引用格式" :origin="{x:'0%', y:'20%'}" :isShow.sync="isQuoteShow" zIndex="999">
      <div class="dialog_body">
        <div class="format_item" v-for="item in quoteTypeList" :key="item.label">

          <div class="formatType">{{item.label}}</div>
          <div class="formatContent my_card">
            <div class="formatText">
              {{citationFormat[item.id]}}
            </div>
            <div class="copy">
              <div class="hand flc" v-clipboard:success="copySuccess" v-clipboard:error="copyError"
                   v-clipboard:copy="citationFormat[item.id]">
                <img src="@/assets/img/docuInfo/fz.png" class="copy_icon" />
                <span class="copy_txt">复制</span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </myDialog>
  </div>
</template>

<script>
import myDialog from '@/components/myDialog/index.vue'
import { getCitationFormat } from '@/api/article.js'
export default {
  props: {
    id: String
  },
  components: {
    myDialog
  },
  data () {
    return {
      isQuoteShow: false,
      citationFormat: {},
      quoteTypeList: [
        {
          id: 'citeAma',
          label: 'AMA'
        },
        {
          id: 'citeApa',
          label: 'APA'
        },
        {
          id: 'citeMla',
          label: 'MLA'
        },
        {
          id: 'citeNlm',
          label: 'NLM'
        }
      ]
    }
  },
  methods: {
    copySuccess () {
      this.$message.success('复制成功')
    },
    copyError () {
      this.$message.error('复制失败')
    },
    // 获取文献引用格式
    async getCitationFormat (fn) {
      const res = await getCitationFormat({ documentId: this.id })
      this.citationFormat = res.data
      fn && fn()
    },
    seeQuote () {
      this.getCitationFormat(() => {
        if (!this.citationFormat.citeAma) return this.$message.warning('暂无引用格式')
        this.isQuoteShow = true
      })
    }
  },
  created () {

  }
}
</script>

<style lang="less" scoped>
.openQuote {
  position: relative;
  top: 0;
  width: 90px;
  height: 32px;
  border-radius: 5px;
  margin: 0 30px 0px 0;
  font-size: 14px;
  color: #4b639f;
  border: 1px solid #4b639f;
  background-color: #f5f7fb;
  &:hover {
    top: -2px;
    transition: all 0.3s;
  }
}
.dialog_body {
  max-height: calc(100vh - 135px);
  padding: 20px;
  overflow-y: auto;
}
.item {
  padding: 10px 20px;
  background-color: #f5f7fb;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}
.format_item {
  display: grid;
  grid-template-columns: 50px calc(100% - 50px);
  align-items: center;
  padding: 10px 10px 10px 20px;
  background-color: #f5f7fb;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  .formatType {
    color: #4b639f;
    font-weight: 600;
    font-size: 14px;
  }
  .formatContent {
    padding: 10px;
  }
  .formatText {
    line-height: 1.5em;
  }
}
.copy {
  display: grid;
  align-items: center;
  justify-content: end;
  margin-top: 5px;
  padding-right: 6px;
  .copy_icon {
    width: 12px;
    height: 12px;
  }
  .copy_txt {
    color: #4b639f;
  }
}
</style>
