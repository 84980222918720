<template>
  <div class="figures_list my_card">
    <ul>
      <li v-for="(item,i) in FiguresList" :key="item.id" @click="openDialog(i)">
        <img :src="item.thumbUrl">
      </li>
    </ul>

    <!-- 预览大图弹框 -->
    <el-dialog ref="elDialog" :visible.sync="isBigImg" width="1100px" :lock-scroll="false"
      :modal-append-to-body="false" @open="open">
      <div class="big_imgs_box" @scroll.stop>
        <div class="left_btn" @click="leftBtn">
          <i class="el-icon-back"></i>
        </div>
        <div class="content_box" ref="contentBox">
          <div class="img_box">
            <img :src="figureItem.url">
          </div>
          <div class="content_text" ref="contentText">
            <div v-html="figureItem.text"></div>
            <span @click="onOpenTextBtn" v-if="isShowBtn" class="open_icon  hand no_select">
              {{isHideBtn?'收起':'查看全部'}}
              <i :class="isHideBtn?'el-icon-arrow-up':'el-icon-arrow-down' "></i>
            </span>

          </div>
        </div>

        <div class="right_btn" @click="rightBtn">
          <i class="el-icon-right"></i>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getFiguresList } from '@/api/article'
export default {
  props: {
    docPmid: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      index: 0, // 选中的索引
      isBigImg: false, // 大图预览状态位
      FiguresList: [], // 图表列表
      figureItem: {}, // 选中的图表
      isShowBtn: false, // 展开盒子
      isHideBtn: false //  按钮状态
    }
  },
  methods: {
    // 弹窗打开事件
    open () {
      this.$nextTick(() => {
        this.setScroll(0)
        this.isShowOpenBtn()
      })
    },
    // 展开按钮
    onOpenTextBtn () {
      this.isHideBtn = !this.isHideBtn
      if (this.isHideBtn) {
        document.querySelector('#imgtext').classList.remove('is_text_hide')
      } else {
        document.querySelector('#imgtext').classList.add('is_text_hide')
      }
    },
    // 展开按钮是否显示
    isShowOpenBtn () {
      const lineNum = document.querySelector('#imgtext').clientHeight
      this.isShowBtn = lineNum > 72
      if (lineNum > 72) {
        document.querySelector('#imgtext').classList.add('is_text_hide')
      }
      this.isHideBtn = false
    },
    // 设置滚动条位置
    setScroll (num) {
      this.$refs.contentBox.scrollTop = num
    },
    // 右边按钮事件
    rightBtn () {
      this.setScroll(0)
      this.index++
      if (this.index === this.FiguresList.length) {
        this.index = 0
      }
      this.figureItem = {
        ...this.FiguresList[this.index],
        // class="is_text_hide"
        text: '<div id="imgtext" > ' + this.FiguresList[this.index].text + '</div>'
      }
      this.$nextTick(() => {
        this.isShowOpenBtn()
      })
    },
    // 左边按钮事件
    leftBtn () {
      this.setScroll(0)
      this.index--
      if (this.index === -1) {
        this.index = this.FiguresList.length - 1
      }
      this.figureItem = {
        ...this.FiguresList[this.index],
        text: '<div id="imgtext" > ' + this.FiguresList[this.index].text + '</div>'
      }
      this.$nextTick(() => {
        this.isShowOpenBtn()
      })
    },
    // 打开预览弹框
    openDialog (i) {
      this.isBigImg = true
      this.index = i
      this.figureItem = {
        ...this.FiguresList[this.index],
        text: '<div id="imgtext"  > ' + this.FiguresList[this.index].text + '</div>'
      }
      this.isHideBtn = false
    },
    // 获取图表列表
    async getFiguresList () {
      const res = await getFiguresList({
        pmid: this.docPmid
      })
      this.FiguresList = res.data.filter(item => item.url)
      return res.data.filter(item => item.url)
    }
  }
  // created () {
  //   this.$nextTick(() => {
  //     this.getFiguresList()
  //   })
  // }
}
</script>
<style lang="less" scoped>
.figures_list {
  margin: 0px 0 20px;
  padding: 20px;
  ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    li {
      width: 120px;
      height: 70px;
      overflow: hidden;
      border: 1px solid #e8e8e8;
      margin: 0 16px 20px 0;
      &:nth-child(6n + 0) {
        margin-right: 0;
      }
      img {
        width: 100%;
      }
    }
  }
  .big_imgs_box {
    display: flex;
    align-items: center;
    justify-content: center;
    .content_box {
      width: 800px;
      margin: 0 50px;
      height: 680px;
      overflow: auto;
    }
    .img_box {
      width: 100%;
      padding: 20px 20px 10px;
      background-color: #fff;
      img {
        width: 100%;
      }
    }
    .content_text {
      width: 100%;
      position: sticky;
      bottom: -1px;
      background-color: rgba(0, 0, 0, 0.8);
      padding: 20px 20px;
      color: #fff;
      line-height: 18px;
      transition: height 0.5s;
    }
    .open_icon {
      position: relative;
      right: -680px;
      top: 10px;
      display: flex;
      align-items: center;
      width: 75px;
      font-size: 12px;
      color: #fff;
      i {
        font-size: 20px;
      }
    }
    /deep/.is_text_hide {
      overflow: hidden;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
    .left_btn,
    .right_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 65px;
      height: 65px;
      border-radius: 50%;
      background-color: #545454;
      color: #fff;
      i {
        font-size: 32px;
      }
    }
  }
  /deep/ .el-dialog {
    position: relative;
    background-color: transparent;
    box-shadow: 0 0 0 0 transparent;
    margin-top: 80px !important;
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__close {
      position: relative;
      right: 50px;
      font-size: 20px;
      color: #fff;
      padding: 6px;
      border: 2px solid #c1c1c1;
      border-radius: 50%;
      transform: rotate(0deg);
      transition: transform 0.5s;
      &:hover {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
