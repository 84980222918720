<template>
  <div class="quotedoc_list loading">
    <docList :docList="citedDocList" />
    <div class="morebtn_box" v-if="citedDocList.length!==0">
      <span v-if="isMore" @click="getMoreCitedDoc" class="getmore_btn">加载更多</span>
      <span v-else>暂无更多</span>
    </div>
  </div>
</template>
<script>
import { getCitedByList } from '@/api/article.js'
import docList from './documentList.vue'
export default {
  props: {
    docPmid: {
      type: Number,
      default: 0
    }
  },
  components: {
    docList
  },
  data () {
    return {
      isMore: false, // 加载更多按钮
      pageObj: {
        pageNum: 1,
        pageSize: 5
      },
      citedDocList: []
    }
  },
  methods: {
    // 加载更多
    getMoreCitedDoc () {
      this.pageObj.pageNum++
      this.getCitedByList()
    },
    // 获取被引用列表
    async getCitedByList () {
      const res = await getCitedByList({
        ...this.pageObj,
        pmid: this.docPmid
      })
      this.isMore = res.data.length >= this.pageObj.pageSize
      this.citedDocList = [...this.citedDocList, ...res.data]
    }
  },
  created () {
    this.getCitedByList()
  }
}
</script>
<style lang="less" scoped>
.quotedoc_list {
  margin-top: 10px;
  margin-bottom: 20px;
  .morebtn_box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    // height: 50px;
    cursor: default;
    .getmore_btn {
      color: #4b639f;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
    }
  }
}
</style>
