<template>
  <div>
    <div class="similar_document_list my_card" v-for="(doc,i) in docList" :key="i">
      <h4 @click="goDetails(doc)" class="doc_title">
        <img v-if="doc.qiniuUrl === '1' && !onIsRevoke(doc.docPublishType)" class="pdf_icon"
             src="@/assets/img/list_pdf_icon.png">
        <img v-if="onIsRevoke(doc.docPublishType) && doc.qiniuUrl !== '1'" class="pdf_icon"
             src="@/assets/img/docuInfo/revoke_icon.png">
        <img v-if="onIsRevoke(doc.docPublishType) && doc.qiniuUrl === '1' " class="pdf_icon"
             src="@/assets/img/docuInfo/revokePdf_icon.png">
        <span>
          {{doc.docTitle}}
        </span>
      </h4>
      <div class="doc_info_box">
        <span>IF: {{doc.docIf}}</span>
        <!-- <span>PMID: {{doc.pmid}}</span> -->
        <span>DOI: {{doc.docDoi}}</span>
        <span>日期: {{$initTime(doc.docPublishTime)}}</span>
      </div>
      <div class="doc_info_box Journal_box">
        <span class="Journal_name  hand" @click="goJournalDetailsPage(doc)">来源期刊:
          <span :class="{'is_hover':doc.journalId}"> {{doc.docSourceJournal || '暂无'}}</span>
        </span>
        <span>被引用: {{doc.citedBy}}</span>
      </div>
      <p class="doc_author">作者: {{doc.docAuthor}}</p>
    </div>
    <placeholder :isShow="docList.length===0" />
  </div>
</template>
<script>
import { pageTurn, blankPagePar } from '@/utils/index.js'
export default {
  props: {
    docList: {
      type: Array,
      default: () => ([])
    },
    // 是否新开页
    isNewPage: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {

    }
  },
  methods: {
    onIsRevoke (val) {
      if (!val) return false
      return ['Retracted Publication', '已撤销出版物', 'Retraction of Publication'].includes(val)
    },
    // 前往期刊详情页
    async goJournalDetailsPage (doc) {
      if (!doc.journalId) return
      blankPagePar('journalDetails', { journalId: doc.journalId })
    },
    // 关联文章跳转详情页面
    goDetails (item) {
      this.$myCount({
        name: 'PC-文献/指南访问',
        itemId: item.id
      })
      if (this.isNewPage) {
        pageTurn('articleDetails', item.id)
      } else {
        this.$router.push({ path: '/articleDetails', query: { id: item.id } })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.similar_document_list {
  margin-bottom: 20px;
  padding: 15px;
  .doc_title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 18px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .doc_info_box {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    padding: 5px 0;
    color: #4d8055;
  }
  .Journal_box {
    padding-top: 0px;
  }
  .doc_author {
    // width: 778px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #666;
    font-size: 14px;
  }
}
.biao {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  font-size: 20px;
  margin-bottom: 10px;
  color: #333;
  font-weight: bold;
}
.pdf_icon {
  position: relative;
  top: -2px;
  width: 16px;
  margin: 0 5px;
}
</style>
