import { render, staticRenderFns } from "./quoteDocument.vue?vue&type=template&id=7c47ca04&scoped=true&"
import script from "./quoteDocument.vue?vue&type=script&lang=js&"
export * from "./quoteDocument.vue?vue&type=script&lang=js&"
import style0 from "./quoteDocument.vue?vue&type=style&index=0&id=7c47ca04&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c47ca04",
  null
  
)

export default component.exports