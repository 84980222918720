<template>
  <div class="article-details">
    <!-- 文章详情页面   @click="$refs.collection. onCloseCollection "-->
    <div class="details-middle">
      <div class="middle-item">
        <div class="middle_top" v-if="isWithdrawnDoc || isWithdrawnNotice">
          <p v-if="isWithdrawnDoc" class="document_type">已撤稿文献</p>
          <p v-if="isWithdrawnNotice" class="document_type">该文献为撤稿通知</p>
          <span @click="seeDocumentDetails" v-if="isWithdrawnNotice">见撤稿文献</span>
          <span @click="seeDocumentDetails" v-if="isWithdrawnDoc">见撤稿通知</span>
        </div>
        <!-- 文章标题 -->
        <div class="left-title">
          {{ article.docTitle}}
          <img src="@/assets/img/docuInfo/fz.png" v-clipboard:copy="messageTitle"
               v-clipboard:success="onCopy" v-clipboard:error="onError" @click="getcopy(0)"
               class="copy  hand" />
        </div>
        <div class="left-title">
          {{ article.docTitleZh}}
        </div>
        <div class="right-top">
          <span v-if="article.qiniuUrl === '1'" class="hand right_top_btn" @click="check"
                :class="{'btn_Disable': article.qiniuUrl !== '1'}">
            <img class="pdf_icon" src="@/assets/img/docuInfo/pdf_icon.png" alt="">
            阅读
            <img src="https://img.infox-med.com/webImg/infoPc/icon/vip_icon_sub.png" alt=""
                 class="vip_sub_icon">
          </span>
          <span v-else class="hand right_top_btn" @click="openGetDocDialog">
            <img class="pdf_icon" src="@/assets/img/docuInfo/pdf_icon.png" alt="">
            原文请求
          </span>

          <collection v-if="!isCollect" ref="collection" :itemId="id" :category="1"
                      @upDataType="collectStatus" />

          <span v-else @click="callCollect" class=" hand isCollect right_top_btn">
            <i class="iconfont icon-a-shoucangshoucanghouicon"></i>
            已收藏
          </span>
          <Quote :id="id" />
        </div>
        <div class="item">
          <div class="middle-right">
            <div class="right-xinxi ">
              <ul class="card">
                <li class="tags_container">
                  <div class="area_tag" v-if="article.zkyArea">中科院分区：{{article.zkyArea}}</div>
                  <div class="cited_tag" v-if="article.citedBy">被引用: {{article.citedBy}}</div>
                </li>
                <li class="docinfo_box">
                  <div class="docinfo_box_item">
                    <span>发表时间：</span>
                    <span>{{$initTime(article.docPublishTime) }}</span>
                  </div>
                  <div class="docinfo_box_item">
                    <span>IF：</span>
                    <span>{{article.docIf === '0.0'? '-' : article.docIf }}</span>
                  </div>
                  <div class="docinfo_box_item Journal_name" @click="goJournalDetailsPage">
                    <span>来源期刊：</span>
                    <span
                          :class="{'is_hover': article.journalId}">{{article.docSourceJournal|| '暂无'}}</span>
                  </div>
                  <!-- <div class="docinfo_box_item">
                    <span>被引用：</span>
                    <span>{{article.citedBy}}</span>
                  </div> -->
                </li>
                <li>
                  <span>DOI：</span>
                  <span>{{article.docDoi || '暂无'}}</span>
                  <img src="@/assets/img/docuInfo/fz.png" v-clipboard:copy="messageTitle"
                       v-clipboard:success="onCopy" v-clipboard:error="onError" @click="getcopy(1)"
                       class="copy  hand hidden-xs-only" />
                </li>
                <li>
                  <span>PMID：</span>
                  <span>{{article.pmid}}</span>
                  <img src="@/assets/img/docuInfo/fz.png" v-clipboard:copy="messageTitle"
                       v-clipboard:success="onCopy" v-clipboard:error="onError" @click="getcopy(2)"
                       class="copy  hand hidden-xs-only" />
                </li>
                <li>
                  <span>作者：</span>
                  <template v-if="authorList.length>0">
                    <span @click="goSearchListPage(item.autName)" class="aut is_comma"
                          v-for="(item, i) in authorList" :key="i">
                      <span class="is_hover">{{item.autName}}</span>
                      <i class="index" v-for="(ai,index) in item.autIndex" :key="index">{{ai}}</i>
                    </span>
                  </template>
                  <template v-else-if="article.docAuthor">
                    <span class="lod_doc_author is_comma is_hover" @click="goSearchListPage(item)"
                          v-for=" (item,i) in lodDocAuthorList" :key="i">{{item}}</span>
                  </template>
                  <span v-else> 暂无 </span>
                </li>
                <!-- 通讯地址 -->
                <li class="aut-addr no_select" v-if="autAddrList.length>0">
                  <span>Affiliations</span>
                  <span class="bom" v-show="!isShow" @click="showAut">+ expand</span>
                  <span class="bom" v-show="isShow" @click="showAut">- collapse</span>
                </li>
                <li>
                  <div class="autaddr_container" v-show="isShow && autAddrList.length>0">
                    <!-- <p class="title">Affiliations</p> -->
                    <div class="aut_item" v-for="(item, i) in autAddrList" :key="i">
                      <i>{{item.autIndex}}. </i>
                      <div class="aut_info">
                        <p>{{item.text}}</p>
                        <p>{{item.textZh}}</p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>

            </div>
          </div>
          <div class="middle-left">

            <div class="left-zhai padding">
              <span>摘要</span>
            </div>
            <!-- 文献摘要 -->
            <div class="card docAbstract" v-if="article.docAbstract"
                 :class="{'row_docAbstract':!article.docAbstractZh}">
              <div class="original docAbstract_card">
                <span v-html="article.docHtml || article.docAbstract"></span>
              </div>
              <div v-if="article.docAbstractZh" class="translateAbs docAbstract_card">
                {{article.docAbstractZh}}
              </div>
            </div>

            <div class="left zanwu card" v-else>
              暂无摘要
            </div>
            <!-- keywords关键词 -->
            <div class="keyword" v-if="keywordList.length!==0 && keywordList[0] !== ' '">
              <span class="biao padding">Keywords</span>
              <div class="card">
                <span class="tag" v-for="(item,i) in keywordList" :key="i">{{item}}</span>
              </div>
            </div>
            <!-- 类型 -->
            <div class="keyword" v-if="documentTypeList.length!==0">
              <span class="biao padding">类型</span>
              <div class="card">
                <span class="tag" v-for="(item,i) in documentTypeList" :key="i">{{item}}</span>
              </div>
            </div>
            <div class="figure_list_box" v-show="isFigures">
              <span class="biao padding">图表</span>
              <figuresList ref="figuresList" :docPmid="article.pmid" />
            </div>
            <!-- 相关内容 -->
            <div id="doc_list_box" class="doc_list_box">
              <el-tabs v-model="selectTabName" @tab-click="selectTabClick">
                <el-tab-pane label="相关文献" name="similarDocument"></el-tab-pane>
                <el-tab-pane label="被引用文献" name="quoteDocument"></el-tab-pane>
                <el-tab-pane label="参考文献" name="referenceDocument"></el-tab-pane>
                <el-tab-pane v-if="isWithdrawnDoc" label="撤稿通知" name="withdrawnDocument">
                </el-tab-pane>
                <el-tab-pane v-if="isWithdrawnNotice" label="撤稿文献" name="withdrawnDocument">
                </el-tab-pane>
              </el-tabs>
              <keep-alive>
                <component :is="selectTabName" :documentId="id" :docPmid="article.pmid"
                           :documentList="WithdrawnDocList"></component>
              </keep-alive>
            </div>

            <!-- MeSH词表 -->
            <div class="MeSH-terms" v-if="MeshTermsList && MeshTermsList.length!==0">
              <span class="biao padding">MeSH terms</span>
              <ul class="document_text">
                <li v-for="(item,i) in MeshTermsList" :key="i">
                  <i class="el-icon-arrow-right"></i>
                  <span class=" hand underline" @click="goPageSearch(item)">{{item}}</span>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- 返回顶部 -->
    <VipTipDialog :show.sync="showVipTip" />
  </div>
</template>
<script>
import { getDocumentById, gainTran, collectStatus, callCollect, getAuthorAddr, getRetractInfo, putViewcount, translateByDocId, getAuthorAddrZh } from '@/api/article.js'
import { blankPagePar, confirm, getCurrentBrowser, getOs } from '@/utils/index.js'
import { submitDocFeedback } from '@/api/feedback'
import { mapGetters } from 'vuex'
import similarDocument from './components/similarDocument.vue'
import quoteDocument from './components/quoteDocument.vue'
import referenceDocument from './components/referenceDocument.vue'
import figuresList from './components/figureList.vue'
import withdrawnDocument from './components/withdrawnDocument.vue'
import VipTipDialog from '@c/vipTipDialog/index.vue'
import Quote from './components/quoteDialog.vue'
export default {
  components: {
    similarDocument,
    quoteDocument,
    referenceDocument,
    figuresList,
    withdrawnDocument,
    VipTipDialog,
    Quote
  },
  data () {
    return {
      selectTabName: 'similarDocument', // 选中的tab栏
      id: null, // 文献ID
      article: {}, // 文章详情
      messageTitle: '', // 复制
      isCollect: null, // 文章收藏状态
      keywordList: [], // 关键词数据
      MeshTermsList: [], // 词表数据
      authorList: [], // 文章作者信息
      autAddrList: [], // 作者通讯地址
      isShow: false, // 通讯盒子显示状态
      pdfUrl: '', // 原文地址
      isqikan: false, // 是否是期刊跳转过来的
      lodDocAuthorList: [], // 无通讯地址的作者信息
      isCollection: false, // 收藏文件夹弹框
      isQuoteShow: false, // 引用弹框状态
      documentTypeList: [], // 文献类型
      isFigures: false, // 图表是否显示
      isWithdrawnDoc: false, // 撤稿文献
      isWithdrawnNotice: false, // 撤稿通知
      WithdrawnDocList: [], // 撤稿文献列表
      showVipTip: false
    }
  },
  methods: {
    async getAuthorAddrZh () {
      const res = await getAuthorAddrZh({
        documentId: this.id
      })
      this.autAddrList.forEach(item => {
        const find = res.data.find(re => re.autIndex === item.autIndex)
        if (find) (item.textZh = find.autAddrCn)
      })
    },
    async translateByDocId () {
      const res = await translateByDocId({
        documentId: this.article.id
      })
      this.article.docTitleZh = res.data.docTitleZh
      this.article.docAbstractZh = res.data.docAbstractZh
    },
    // 请求文献
    async openGetDocDialog () {
      if (!this.userId) {
        return this.$login()
      }
      const pmid = JSON.parse(sessionStorage.getItem('getDocPmid') || '[]')
      if (pmid.includes(this.article.pmid)) return this.$myMessage.warning('该文献已反馈, 请等待通知')
      confirm({
        warning: '是否确认提交?',
        success: async () => {
          await submitDocFeedback({
            documentId: this.article.id,
            documentPmid: this.article.pmid,
            documentTitle: this.article.docTitle,
            documentDoi: this.article.docDoi,
            feedbackType: '文献求助',
            feedbackContent: `PMID: ${this.article.pmid}, 标题: ${this.article.docTitle},   DOI: ${this.article.docDoi || '--'}`,
            deviceName: getCurrentBrowser(),
            systemVersion: 'PC',
            terminalType: getOs(),
            appVersion: 'PC'
          })
          this.$message.success('感谢您的反馈, 我们会尽快处理, 请留意原文更新的消息通知！')
          pmid.push(this.article.pmid)
          sessionStorage.setItem('getDocPmid', JSON.stringify(pmid))
        },
        catch: (err) => {
          console.log(err)
          this.$message.error('反馈失败')
        }
      })
    },
    // 统计用户查看文献
    async putViewcount () {
      putViewcount({
        category: 1,
        itemId: this.article.id
      })
    },
    // 撤稿通知查看原文
    seeDocumentDetails () {
      document.querySelector('#doc_list_box').scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      })
      this.selectTabName = 'withdrawnDocument'
    },
    // 获取撤稿信息
    async getWithdrawnDoc (type) {
      const res = await getRetractInfo({
        pmid: this.article.pmid,
        type: type
      })
      this.WithdrawnDocList = res.data
    },
    // 相关文献tab切换事件
    selectTabClick () {
      const docY = document.documentElement.scrollTop
      if (docY > 900) {
        document.documentElement.scrollTop = 810
      }
    },
    // 前往期刊详情页
    async goJournalDetailsPage () {
      if (!this.article.journalId) return
      blankPagePar('journalDetails', { journalId: this.article.journalId })
    },
    goSearchListPage (name) {
      const str = name.replace(/\*/g, '')
      blankPagePar('searchList', { val: str })
    },
    // 关闭/打开通讯地址盒子
    showAut () {
      this.isShow = !this.isShow
      if (this.isShow && !this.autAddrList[0].textZh) {
        this.getAuthorAddrZh()
      }
    },
    // 获取作者信息和通讯地址
    async getAuthorAddr () {
      const res = await getAuthorAddr({ documentId: this.id })
      res.data = res.data.sort((a, b) => a.autIndex - b.autIndex)
      this.authorList = res.data?.reduce((res, item) => {
        const find = res.find(r => r.autName === item.autName)
        if (find) {
          if (!find.autIndex.includes(item.autIndex)) find.autIndex.push(item.autIndex)
        } else {
          res.push({
            ...item,
            autIndex: item.autIndex ? [item.autIndex] : [],
            sortIndex: this.article.docAuthor.indexOf(item.autName)
          })
        }
        return res
      }, []).sort((a, b) => a.sortIndex - b.sortIndex)

      this.autAddrList = res.data.reduce((r, item) => {
        const find = r.find(data => data.autIndex === item.autIndex)
        if (!find && item.autIndex !== 0) {
          r.push({
            autIndex: item.autIndex,
            text: item.autAddr,
            textZh: ''
          })
        }
        return r
      }, [])
    },
    // 点击mesh词表跳转到搜索页面
    goPageSearch (val) {
      const str = val.replace(/\*/g, '')
      this.$router.push({ path: '/searchList', query: { val: str } })
    },
    // 查看更多
    lookMore () {
      // 跳转到搜索页面
      this.$router.push({ path: '/searchList', query: { val: 'relation' } })
    },
    // 查看原文
    async check () {
      if (this.article.qiniuUrl !== '1') return
      // 判断用户是否登录
      this.$myCount({
        name: 'PC-阅读原文',
        itemId: this.id
      })
      if (!this.userId) {
        return this.$login()
      }
      if (!this.userInfo.isHy) return (this.showVipTip = true)
      blankPagePar('pdfDetails', { id: this.id })
    },
    // 取消收藏文章
    callCollect () {
      this.$confirm('确认取消收藏该文献吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(async () => {
        const res = await callCollect({
          category: 1,
          itemId: this.id,
          userId: this.userId
        })
        if (res.code !== 0) return this.$message.error('取消收藏失败, 请重试')
        this.isCollect = false
        this.$message.success('取消收藏成功')
      }).catch((e) => {
        if (e !== 'cancel') return this.$message.error('操作失败')
      })
    },
    async translateSever (text) {
      if (!text) return ''
      return await gainTran({ text })
    },
    setTranslateList () {
      const textconst = this.article.docAbstract.split('. ')
      let i = 0
      let item = ''
      const translateList = []
      while (i <= textconst.length && textconst[i]) {
        while ((item + textconst[i]).length <= 5000 && textconst[i]) {
          item += textconst[i]
          i++
        }
        translateList.push(item + '. ')
        item = ''
      }
      return translateList
    },
    // 翻译文章内容和标题 停用
    async translate () {
      // if (!this.userId) return this.$login()
      const ps = []
      const docTitle = this.translateSever(this.article.docTitle)
      ps.push(docTitle)
      const constLength = this.article.docAbstract
      if (constLength <= 5000) {
        const docAbstract = this.translateSever(constLength)
        ps.push(docAbstract)
      } else {
        this.setTranslateList().forEach(item => ps.push(this.translateSever(item)))
      }
      Promise.all(ps).then(resArr => {
        this.article.docTitleZh = resArr[0]?.data || ''
        resArr.slice(1).forEach(item => (this.article.docAbstractZh += (item?.data || '')))
      })
    },
    // 点击复制
    getcopy (id) {
      // 0: 标题复制  1:DOI复制 2:PMID复制  3:引用格式复制
      const copyMap = {
        // 0: () => (this.showBox ? this.shareTitle : this.article.docTitle),
        0: () => this.article.docTitle,
        1: () => this.article.docDoi,
        2: () => this.article.pmid
      }
      this.messageTitle = copyMap[id]()
    },
    // 复制成功
    onCopy () {
      this.$message.success('复制成功')
    },
    // 复制失败
    onError () {
      this.$message.error('复制失败')
    },
    // 获取文章详情
    async getDocumentById () {
      try {
        const res = await getDocumentById({
          documentId: this.id
        })
        this.article = res.data
        const keywordSymbol = res.data.docKeywords.indexOf(';') !== -1 ? ';' : ','
        this.keywordList = res.data.docKeywords && res.data.docKeywords.split(keywordSymbol)
        this.documentTypeList = res.data.docPublishType ? res.data.docPublishType.split(';') : []
        this.MeshTermsList = res.data.docMeshTerms && res.data.docMeshTerms.split('$$')
        this.lodDocAuthorList = res.data.docAuthor.split(',')
        this.isWithdrawnDoc = res.data.docPublishType.indexOf('已撤销出版物') !== -1
        this.isWithdrawnNotice = res.data.docPublishType.indexOf('出版撤销声明') !== -1
        if (this.userId && !this.article.docTitleZh) {
          this.translateByDocId()
        }
        if (this.isWithdrawnNotice) {
          // 是撤销通知 获取撤稿本稿列表
          this.getWithdrawnDoc(0)
        }
        if (this.isWithdrawnDoc) {
          // 是撤稿本搞  获取撤稿通知列表
          this.getWithdrawnDoc(1)
        }
        this.putViewcount() // 统计用户访问历史记录
        this.$nextTick(async () => {
          const data = await this.$refs.figuresList.getFiguresList()
          this.isFigures = data.length > 0
        })
        this.getAuthorAddr()
      } catch (error) {
        return this.$message.error('获取文章详情失败')
      }
    },
    // 获取文章收藏状态
    async collectStatus () {
      if (!this.userId) return
      try {
        const res = await collectStatus({
          category: 1,
          itemId: this.id,
          userId: this.userId
        })
        this.isCollect = res.data
      } catch (error) {
        return this.$message.error('查询文章收藏状态失败')
      }
    }
  },
  computed: {
    ...mapGetters(['userId', 'userInfo'])
  },
  created () {
    this.id = this.$route.query.id
    this.getDocumentById() // 获取文章详情
    this.collectStatus() // 获取文章收藏状态
  },
  watch: {
    $route (to, from) {
      this.id = this.$route.query.id
      this.getDocumentById() // 获取文章详情
      this.collectStatus() // 获取文章收藏状态
      this.selectTabName = 'similarDocument'
    }
  }
}
</script>
<style lang="less" scoped>
.article-details {
  // display: flex;
  // flex-direction: column;
  min-height: 100%;
  color: #4b639f;

  // 公共样式
  .card {
    padding: 20px;
    background-color: #fff;
    // border: 1px solid #e8e8e8;
    box-shadow: 0 0 6px 0 #e8e8e8;
    border-radius: 5px;
  }
  .padding {
    padding: 0 20px;
  }
  .Journal_name {
    cursor: pointer;
  }

  // 中部盒子
  .details-middle {
    padding-bottom: 1px;
    background-color: #f5f7fb;
    // 顶部撤稿通知
    .middle_top {
      position: relative;
      width: 100%;
      background-color: #f8dedd;
      padding: 15px 44px;
      border-radius: 8px;
      margin-bottom: 20px;
      &::after {
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        width: 12px;
        height: 100%;
        border-radius: 8px 0 0 8px;
        background-color: #e31c3d;
      }
      .document_type {
        font-size: 16px;
        font-weight: 600;
        color: #971a1e;
        line-height: 25px;
      }
      span {
        cursor: pointer;
        font-size: 14px;
        color: #971a1e;
      }
    }
    .middle-item {
      width: 1200px;
      min-height: 820px;
      margin: 0 auto;
      padding-top: 20px;
      background-color: #f5f7fb;
      .left-title {
        // width: 70%;
        font-size: 26px;
        margin-bottom: 18px;
        line-height: 35px;
        font-weight: bold;
        color: #000000;
        padding-left: 20px;
      }
      .right-top {
        display: flex;
        padding-left: 20px;
        .right_top_btn {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          top: 0;
          width: 90px;
          height: 32px;
          border-radius: 5px;
          margin: 0 30px 0px 0;
          font-size: 14px;
          color: #4b639f;
          border: 1px solid #4b639f;
          &:hover {
            top: -2px;
            transition: all 0.3s;
          }
          &:first-child {
            background: #4b639f;
            color: #fff;
          }
          .pdf_icon {
            width: 16px;
            height: 16px;
            margin-right: 3px;
          }
        }
        .vip_sub_icon {
          position: absolute;
          top: 1px;
          right: 0;
          width: 19px;
        }
        .isCollect {
          border: 1px solid #4b639f;
          background-color: #f5f7fb;
          color: #4b639f;
        }
      }

      .item {
        // display: flex;
        min-height: 740px;
        padding-top: 20px;
        .middle-left {
          width: 100%;
          .left-zhai {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 40px;
            margin-top: 10px;
            font-weight: bold;
            span {
              font-size: 18px;
              color: #333;
              font-weight: 700;
            }
            i {
              font-size: 16px;
            }
          }
          .zanwu {
            font-size: 16px;
            padding: 30px 0;
            text-align: center;
            color: #000000;
          }
          .keyword {
            margin: 15px 0;
            .tag {
              display: inline-block;
              padding: 5px 15px;
              margin: 5px 20px 5px 0px;
              font-size: 14px;
              background-color: #f0f0f0;
              border-radius: 15px;
              color: #000;
            }
          }
          /deep/ .el-tabs {
            position: sticky;
            top: 0;
            margin-top: 20px;
            padding: 0 5px;
            background-color: #f5f7fb;
            .el-tabs__active-bar {
              background-color: transparent;
              // background-color: #4b639f;
            }
            .el-tabs__item:hover {
              color: #4b639f;
            }
            .is-active {
              color: #4b639f;
              font-size: 18px !important;
              font-weight: 600;
            }
            .el-tabs__item {
              position: relative;
              font-size: 16px;
              padding: 0 15px;
            }
            .el-tabs__header {
              margin-bottom: 0px;
            }
            .el-tabs__nav-wrap::after {
              background-color: transparent;
            }
          }

          .biao {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 40px;
            font-size: 18px;
            margin-top: 10px;
            color: #333;
            font-weight: bold;
          }
          .MeSH-terms {
            margin: 15px 0;
            li {
              // height: 30px;
              line-height: 30px;
              font-size: 16px;
              padding-left: 10px;
              i {
                margin-right: 5px;
              }
            }
          }

          .docAbstract {
            display: grid;
            grid-template-columns: 780px 360px;
            column-gap: 20px;
            font-size: 16px;
            line-height: 1.5em;
            color: #000;
            .docAbstract_card {
              padding: 20px;
              background-color: #f5f7fb;
              border-radius: 5px;
            }
          }
          .row_docAbstract {
            grid-template-columns: 100%;
            column-gap: 0px;
          }
        }

        .middle-right {
          position: relative;
          width: 100%;
          .right-xinxi {
            ul {
              // margin: 0px 0 20px;
              font-size: 14px;
              color: #666;
              line-height: 25px;
              li {
                // margin-bottom: 5px;
                .aut {
                  cursor: pointer;
                  word-break: break-all;

                  .index {
                    display: inline-block;
                    position: relative;
                    top: -8px;
                    width: 15px;
                    height: 15px;
                    font-size: 12px;
                    line-height: 15px;
                    text-align: center;
                    border-radius: 50%;
                    background-color: #e1e1e1;
                    margin-left: 3px;
                    &:first-child {
                      margin-left: 0;
                    }
                  }
                }
                .lod_doc_author {
                  margin-right: 10px;
                  cursor: pointer;
                  word-break: break-all;
                }
                .is_comma {
                  &::after {
                    content: ', ';
                  }
                  &:last-child::after {
                    content: '';
                  }
                }
              }
              .docinfo_box {
                display: flex;
                flex-wrap: wrap;
                .docinfo_box_item {
                  position: relative;
                  margin-right: 25px;
                  &::after {
                    position: absolute;
                    right: -15px;
                    content: '/';
                    font-weight: 700;
                    color: #000;
                  }
                  &:last-child::after {
                    content: '';
                  }
                }
              }
              .aut-addr {
                width: 110%;
                span {
                  margin-right: 15px;
                }
                .bom {
                  color: #4b639f;
                  cursor: pointer;
                  padding: 5px;
                  border-radius: 5px;
                  &:hover {
                    background-color: #d7dce9;
                  }
                }
              }
              .show-box {
                height: 800px;
                border: 1px solid #9faccc;
              }
            }
            .autaddr_container {
              display: grid;
              align-items: center;
              row-gap: 5px;
              padding: 5px 0 0 15px;
              font-size: 14px;
              .aut_item {
                display: grid;
                grid-template-columns: auto 1fr;
                column-gap: 5px;
                align-items: start;
                justify-content: start;
                i {
                  min-width: 25px;
                }
                .aut_info {
                  width: 100%;
                  word-break: break-all;
                  p {
                    line-height: 1.5em;
                  }
                }
              }
            }
          }
          .tags_container {
            display: grid;
            grid-template-columns: auto auto;
            align-items: center;
            justify-content: start;
            column-gap: 10px;
            margin-bottom: 5px;
            line-height: 21px;
            .area_tag,
            .cited_tag {
              font-size: 12px;
              padding: 0 8px;
              border: 1px solid;
              border-radius: 3px;
            }
            .area_tag {
              border-color: #507770;
              background-color: #eaf9f6;
              color: #507770;
            }
            .cited_tag {
              border-color: #b66b3a;
              color: #b66b3a;
              background-color: #fae6d9;
            }
          }
          h3 {
            font-size: 20px;
            height: 40px;
            line-height: 40px;
            font-weight: bold;
            color: #000;
            margin-bottom: 10px;
          }
        }
      }
      .copy {
        margin-left: 12px;
        width: 20px;
        height: 20px;
      }
      .hidden-xs-only {
        width: 15px;
        height: 15px;
      }
    }
  }
}
</style>
