<template>
  <div class="similar_document">
    <docList :docList="documentList" :isNewPage="false" />
  </div>
</template>
<script>
import docList from './documentList.vue'
export default {
  props: {
    documentList: {
      type: Array,
      default: () => []
    }
  },
  components: {
    docList
  },
  data () {
    return {
      relevantList: [] // 相关文章数据
    }
  },
  methods: {
  },
  created () {
  }
}
</script>
<style lang="less" scoped>
.similar_document {
  margin-top: 10px;
}
</style>
