<template>
  <div class="similar_document">
    <docList :docList="relevantList" />
  </div>
</template>
<script>
import { gainDocu } from '@/api/article.js'
import docList from './documentList.vue'
export default {
  props: {
    documentId: {
      type: String,
      default: ''
    }
  },
  components: {
    docList
  },
  data () {
    return {
      relevantList: [] // 相关文章数据
    }
  },
  methods: {

    // 获取相关文献
    async gainDocu () {
      try {
        const res = await gainDocu({
          documentId: this.documentId
        })
        this.relevantList = res.data
      } catch (error) {
        return this.$message.error('获取相关文章失败')
      }
    }
  },
  created () {
    this.gainDocu()
  }
}
</script>
<style lang="less" scoped>
.similar_document {
  // margin-top: 10px;
}
</style>
